.public-DraftEditor-content .align-right .public-DraftStyleDefault-ltr {
  text-align: right !important; /* Reset LTR behavior */
}

.public-DraftEditor-content .align-left .public-DraftStyleDefault-ltr {
  text-align: left !important; /* Reset LTR behavior */
}

.public-DraftEditor-content .align-center .public-DraftStyleDefault-ltr {
  text-align: center !important; /* Reset LTR behavior */
}

.public-DraftEditor-content .align-justify .public-DraftStyleDefault-ltr {
  text-align: justify !important; /* Reset LTR behavior */
}
